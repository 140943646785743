import React from 'react';
import { numberToCurrency } from '../../utils/string';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ProductBenefits, ProductSavings } from '../../queries';

export const TreeMapGraph = ({
    data,
    loading,
    isNex,
}: {
    data: ProductBenefits[] | ProductSavings[];
    loading: boolean;
    isNex: boolean;
}) => {
    const dataWithQuantity = isNex ? data : data.map((pb) => ({ ...pb, pacientes: pb.cantidad }));
    const totalNEX = dataWithQuantity.reduce(
        (acc, productSaving) => acc + productSaving.cantidad,
        0,
    );

    const calculatePercentage = (partialValue: number, totalValue: number) => {
        return Math.floor((partialValue / totalValue) * 100);
    };

    const getColorRanges = () => {
        const maxNEX = Math.max(...dataWithQuantity.map((productSaving) => productSaving.cantidad));
        const step = Math.ceil(maxNEX / 7);

        return [
            { from: 0, to: step, color: '#008e99' },
            { from: step + 1, to: step * 2, color: '#00808a' },
            { from: step * 2 + 1, to: step * 3, color: '#00727a' },
            { from: step * 3 + 1, to: step * 4, color: '#00636b' },
            { from: step * 4 + 1, to: step * 5, color: '#00555c' },
            { from: step * 5 + 1, to: step * 6, color: '#00474d' },
            { from: step * 6 + 1, to: maxNEX, color: '#00393d' },
        ];
    };

    const getNPatientsFromData = (index: number) => dataWithQuantity[index].pacientes;

    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            type: 'treemap',
            animations: { speed: 250 },
        },
        tooltip: {
            x: {
                formatter: (val) => {
                    const xData = val as unknown as string[];
                    return xData.join(', ');
                },
            },
            y: {
                formatter: (val, opts) => {
                    return `${getNPatientsFromData(opts.dataPointIndex)} ${
                        isNex ? 'Pacientes' : 'Bonificaciones'
                    }`;
                },
            },
        },
        series: [
            {
                data: dataWithQuantity.map((productSaving) => ({
                    x: [
                        ` ${productSaving.flia_producto}`,
                        ` ${numberToCurrency(productSaving.cantidad)} ${
                            isNex ? 'NEX' : 'Bonificaciones'
                        }`,
                        ` ${calculatePercentage(productSaving.cantidad, totalNEX)}%`,
                        isNex ? ` ${productSaving.pacientes} Pacientes` : '',
                    ],
                    y: productSaving.cantidad,
                })),
            },
        ],
    };

    return (
        <ReactApexChart
            className='treemap-graphic'
            options={{
                chart: options.chart,
                noData: { text: loading ? 'Cargando...' : 'No hay datos' },
                plotOptions: { treemap: { colorScale: { ranges: getColorRanges() } } },
                states: { hover: { filter: { type: 'darken' } } },
                tooltip: options.tooltip,
            }}
            height={300}
            type='treemap'
            series={options.series}
        />
    );
};
