import React from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { numberToCurrency } from '../../utils/string';
import { MonthlyTokensSavings } from '../../queries';

export const BarsGraph = ({
    data,
    loading,
    isNex,
}: {
    data: MonthlyTokensSavings[];
    loading: boolean;
    isNex: boolean;
}) => {
    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            animations: { speed: 250 },
        },
        noData: { text: loading ? 'Cargando...' : 'No hay datos' },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: { position: 'bottom' },
            },
        },
        tooltip: {
            y: {
                formatter: (val) => `${numberToCurrency(val)} ${isNex ? 'NEX' : 'Bonificaciones'}`,
                title: { formatter: () => '' },
            },
        },
        yaxis: { labels: { formatter: (value) => numberToCurrency(value) } },
        dataLabels: { enabled: false },
        series: [
            {
                data: data.map((monthlyTokensSaving) => ({
                    x: monthlyTokensSaving.mes,
                    y: monthlyTokensSaving.cantidad,
                })),
                color: '#008E99',
            },
        ],
    };

    return (
        <ReactApexChart
            className='bar-graphic'
            options={{
                chart: options.chart,
                plotOptions: options.plotOptions,
                dataLabels: options.dataLabels,
                states: { hover: { filter: { type: 'darken' } } },
                tooltip: options.tooltip,
                yaxis: options.yaxis,
            }}
            height={300}
            type='bar'
            series={options.series}
        />
    );
};
