import actions, {
    GET_ADDRESS_VALIDATION,
    GET_WALLET_CONTACTS,
    GET_WALLET_DETAILS,
    POST_SEND_NEX,
    ADD_CONTACT,
    EDIT_CONTACT,
    GET_CONTACT,
    DELETE_CONTACT,
    GET_RECENT_TRANSACTIONS,
    GET_BALANCE_NEX_MONETARY_BASE,
    ADD_WHITELISTERS,
    GET_EMIT_MINT,
    GET_REMOVE_BURN,
    GET_TOTAL_PUBLISHED,
    GET_WHITELISTERS,
    REMOVE_WHITELISTERS,
    GET_W3MM,
    PUT_PAYMENT_REGISTER,
} from './wallet.actions';
import services from './wallet.services';
import { KeyManager } from 'jasper-roche-crypto';
import keysStorage from '../utils/storage/keys';

const walletMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
        const getKeysFromKeystore = (username) => {
            const keyManager = new KeyManager(username);
            const fundsKey = keyManager.deriveFundsKey();
            const publicKey = keyManager.getPublicKey(fundsKey);
            const address = keyManager.getAddress(publicKey);

            return { address, fundsKey };
        };

        next(action);

        const fromGweiToEth = (gwei) => {
            return Math.round(gwei / 1000000000000000) / 1000;
        };

        const amountFormat = (amount) =>
            parseFloat(amount.replaceAll('.', '').replaceAll(',', '.')).toString();

        switch (action.type) {
            case GET_WALLET_DETAILS:
                {
                    const mnemonic = await keysStorage.getMnemonicFromKeystore(
                        getState().profile.user.username,
                    );
                    if (mnemonic) {
                        const { address, fundsKey } = getKeysFromKeystore(
                            getState().profile.user.username,
                        );
                        services
                            .getWalletBalance(address, fundsKey)
                            .then((data) => {
                                const balance = fromGweiToEth(data);
                                dispatch(
                                    actions.getWalletDetailsResponse({
                                        address: address,
                                        balance: balance,
                                    }),
                                );
                            })
                            .catch((err) => dispatch(actions.getWalletDetailsError(err)));
                    }
                }
                break;
            case GET_RECENT_TRANSACTIONS:
                services
                    .getRecentTransactions()
                    .then((res) => dispatch(actions.getRecentTransactionsResponse(res)))
                    .catch((err) => dispatch(actions.getRecentTransactionsError(err)));
                break;
            case GET_ADDRESS_VALIDATION:
                services
                    .getAddressValidation(action.address)
                    .then((res) =>
                        dispatch(actions.getAddressValidationResponse(setInformacionAddress(res))),
                    )
                    .catch((err) => dispatch(actions.getAddressValidationError(err)));
                break;
            case POST_SEND_NEX: {
                const user = getState().profile.user;
                const { address, fundsKey } = getKeysFromKeystore(user.username);
                const balance = getState().wallet.data.balance;
                if (balance < amountFormat(action.address.amount))
                    dispatch(actions.postSendNexError({ message: 'Fondos Insuficientes' }));
                else
                    services
                        .postSendNex(
                            action.address.address,
                            amountFormat(action.address.amount),
                            address,
                            fundsKey,
                        )
                        .then((res) => {
                            dispatch(actions.postSendNexResponse(res));
                            dispatch(
                                actions.putPaymentRegister({
                                    id_from: user.id,
                                    type_from: user.type,
                                    address_from: action.address.address,
                                    address_to: address,
                                    amount: action.address.amount,
                                }),
                            );
                        })
                        .catch((err) => {
                            if (err.toString().includes('invalid address')) {
                                dispatch(actions.postSendNexError({ message: 'Address Invalida' }));
                            } else dispatch(actions.postSendNexError());
                        });
                break;
            }
            case ADD_CONTACT:
                services
                    .addContact(action.contact)
                    .then((data) => dispatch(actions.addContactResponse(data)))
                    .catch((err) => dispatch(actions.addContactError(getEmptyFields(err.data))));
                break;
            case EDIT_CONTACT:
                services
                    .editContact(action.contact)
                    .then((data) => dispatch(actions.editContactResponse(data)))
                    .catch((err) => dispatch(actions.editContactError(getEmptyFields(err.data))));
                break;
            case DELETE_CONTACT:
                services
                    .deleteContact(action.contactId)
                    .then((data) => dispatch(actions.deleteContactResponse(data)))
                    .catch((err) => dispatch(actions.deleteContactError(err)));
                break;
            case GET_WALLET_CONTACTS:
                services
                    .getContacts()
                    .then((data) => dispatch(actions.getContactsResponse(data)))
                    .catch((err) => dispatch(actions.getContactsError(err)));
                break;
            case GET_CONTACT:
                services
                    .getContact(action.contactId)
                    .then((data) => dispatch(actions.getContactResponse(data)))
                    .catch((err) => dispatch(actions.getContactError(err)));
                break;
            case GET_W3MM:
                services
                    .getWeb3MM()
                    .then((data) => dispatch(actions.getW3mmResponse(data)))
                    .catch((err) => dispatch(actions.getW3mmError(err)));
                break;
            case GET_BALANCE_NEX_MONETARY_BASE:
                const { address } = getKeysFromKeystore(getState().profile.user.username);
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .getBalanceNexMonetaryBase(w3mm)
                            .then((balance) =>
                                dispatch(
                                    actions.getBalanceNexMonetaryBaseResponse({
                                        address: w3mm.currentProvider.selectedAddress,
                                        balance: fromGweiToEth(balance),
                                    }),
                                ),
                            )
                            .catch((err) => dispatch(actions.getBalanceNexMonetaryBaseError(err)));
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;

            case GET_TOTAL_PUBLISHED:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .getTotalPublished(w3mm)
                            .then((balance) =>
                                dispatch(actions.getTotalPublishedResponse(fromGweiToEth(balance))),
                            )
                            .catch((err) => dispatch(actions.getTotalPublishedError(err)));
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;
            case GET_EMIT_MINT:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .toEmitMint(w3mm, w3mm.currentProvider.selectedAddress, action.mint)
                            .then((response) => {
                                dispatch(actions.getEmitMintResponse(response));
                                dispatch(actions.getTotalPublished());
                            })
                            .catch((error) => {
                                dispatch(actions.getEmitMintError(error));
                            });
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));

                break;
            case GET_REMOVE_BURN:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .toremoveBurn(w3mm, w3mm.currentProvider.selectedAddress, action.burn)
                            .then((response) => {
                                dispatch(actions.getRemoveBurnResponse(response));
                                dispatch(actions.getTotalPublished());
                            })
                            .catch((error) => {
                                dispatch(actions.getRemoveBurnError(error));
                            });
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;

            case GET_WHITELISTERS:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .getWhitelisters(w3mm, w3mm.currentProvider.selectedAddress)
                            .then((response) => {
                                dispatch(actions.getWhitelistersResponse(response));
                            })
                            .catch((error) => {
                                dispatch(actions.getWhitelistersError(error));
                            });
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;
            case ADD_WHITELISTERS:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .addToWhitelisters(
                                action.address,
                                w3mm,
                                w3mm.currentProvider.selectedAddress,
                            )
                            .then((response) => {
                                dispatch(actions.addWhitelistersResponse(response));
                                dispatch(actions.getWhitelisters());
                            })
                            .catch((error) => {
                                dispatch(actions.addWhitelistersError(error));
                            });
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;
            case REMOVE_WHITELISTERS:
                services
                    .getWeb3MM()
                    .then((w3mm) => {
                        services
                            .removeFromWhitelisters(
                                action.address,
                                w3mm,
                                w3mm.currentProvider.selectedAddress,
                            )
                            .then((response) => {
                                dispatch(actions.removeWhitelistersResponse(response));
                                dispatch(actions.getWhitelisters());
                            })
                            .catch((error) => {
                                dispatch(actions.removeWhitelistersError(error));
                            });
                    })
                    .catch((error) => dispatch(actions.getW3mmError(error)));
                break;
            case PUT_PAYMENT_REGISTER: {
                services
                    .putPaymentRegister(action.payload)
                    .then((res) => dispatch(actions.putPaymentRegisterResponse(res)))
                    .catch((err) => dispatch(actions.putPaymentRegisterError(err)));
                break;
            }
        }

        const getEmptyFields = (err) => {
            const emptyFields = [];
            if (err.nombre) {
                emptyFields.push('nombre');
            }
            if (err.address) {
                emptyFields.push('address');
            }
            if (err.email) {
                emptyFields.push('email');
            }
            return emptyFields;
        };

        const setInformacionAddress = (infoAddress) => {
            return {
                ...infoAddress,
                nombre: infoAddress?.nombre || infoAddress?.descr,
            };
        };
    };

export default walletMiddleware;
