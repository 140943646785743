import React, { useEffect, useMemo, useState } from 'react';
import './RocheDashboard.scss';
import { connect } from 'react-redux';
import DateRangePicker from '../../common/components/DateRangePicker';
import { useRocheDashboardQuery, useRocheGetAllOSQuery } from '../../queries';
import Loader from '../../common/components/Loader';
import { format, parse } from 'date-fns';
import MigrationModal from '../components/MigrationModal/MigrationModal';
import actions from '../../actions';
import { Select, SelectOption } from './select/Select';
import { I18n } from 'react-redux-i18n';
import { BenefitsTable } from '../benefits-table/BenefitsTable';
import { BarsGraph } from '../graphs/BarsGraph';
import { numberToCurrency } from '../../utils/string';
import { TreeMapGraph } from '../graphs/TreeMapGraph';

interface DashboardProps {
    migrationStatus: any;
    userType: any;
    osMigrated: any;
    osId: any;
    migratePatients: () => void;
    logout: () => void;
}

const RocheDashboard = (props: DashboardProps) => {
    const [defaultStartDate, setDefaultStartDate] = useState<string | null>(null);
    const [defaultEndDate, setDefaultEndDate] = useState<string | null>(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedOS, setSelectedOS] = useState<string | null>(null);
    const {
        data: dashboardData,
        isLoading: dashboardDataLoading,
        refetch,
    } = useRocheDashboardQuery({
        startDate,
        endDate,
        defaultStartDate,
        defaultEndDate,
        selectedOS,
    });

    const { data: allOS, isLoading: isAllOSLoading } = useRocheGetAllOSQuery();

    const loading = dashboardDataLoading || isAllOSLoading;

    const showBeneftsData =
        dashboardData &&
        dashboardData.bonificaciones_por_producto?.length > 0 &&
        dashboardData.bonificaciones_por_mes?.length > 0;

    useEffect(() => {
        if (dashboardData?.desde) setDefaultStartDate(dashboardData.desde);
        if (dashboardData?.hasta) setDefaultEndDate(dashboardData.hasta);
    }, [dashboardData]);

    const getCardsData = () => {
        let result = [
            {
                title: I18n.t('dashboard.roche.cards.totalPatients'),
                value: numberToCurrency(dashboardData?.pacientes_totales || 0),
            },
            {
                title: I18n.t('dashboard.roche.cards.contracts'),
                value: numberToCurrency(dashboardData?.contratos || 0),
            },
            {
                title: I18n.t('dashboard.roche.cards.nexTotalReceived'),
                value: numberToCurrency(dashboardData?.tokens_totales_recibidos || 0),
            },
        ];
        if (showBeneftsData) {
            result.push({
                title: I18n.t('dashboard.roche.cards.benefitsTotal'),
                value: numberToCurrency(dashboardData?.bonificaciones_totales || 0),
            });
        }
        return result;
    };

    const OSSelectOptions: SelectOption[] = useMemo(() => {
        return (allOS || []).map((os) => ({ value: os.id.toString(), label: os.razon_social }));
    }, [allOS]);

    return (
        <div className='roche-dashboard'>
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
            />
            <Select
                onChange={(option) => {
                    setSelectedOS(option.value);
                    refetch();
                }}
                options={OSSelectOptions}
                placeholder='Pagador'
                value={selectedOS}
            />
            <div className='cards-container'>
                {getCardsData().map((cardData, i) => (
                    <div key={`dashboard-card-${i}`} className='dashboard-card'>
                        <h3 className='title'>{cardData.title}</h3>
                        {loading ? <Loader /> : <h1 className='value'>{cardData.value}</h1>}
                    </div>
                ))}
            </div>
            <div className='graphs-container'>
                <div className='filters'>
                    <DateRangePicker
                        allTransactions={[]}
                        textToSearch=''
                        startDate={setStartDate}
                        endDate={setEndDate}
                        updateFilteredTransactionsByDateRange={() => null}
                        clearDateRangeSelection={() => {
                            if (!dashboardData) return;
                            setStartDate(
                                format(
                                    parse(dashboardData?.desde, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                            setEndDate(
                                format(
                                    parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                        }}
                        searchText={() => null}
                        setPage={() => null}
                        showMonthYearPicker
                        receiveDates={
                            dashboardData
                                ? {
                                      startDate: parse(dashboardData.desde, 'MMyyyy', new Date()),
                                      endDate: parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                  }
                                : undefined
                        }
                        notShowReset
                        startDateLabel={I18n.t('dashboard.roche.filters.from')}
                        endDateLabel={I18n.t('dashboard.roche.filters.to')}
                    />
                </div>
                <div className='graphs-row'>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.roche.graphics.savingsPerProductTitle')}</h3>
                        <TreeMapGraph
                            data={dashboardData?.ahorro_por_producto || []}
                            loading={loading}
                            isNex
                        />
                    </div>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.roche.graphics.savingsMonthlyTitle')}</h3>
                        <BarsGraph
                            data={dashboardData?.ahorro_por_mes || []}
                            loading={loading}
                            isNex
                        />
                    </div>
                </div>
                {showBeneftsData && (
                    <div className='graphs-row'>
                        <div className='graph-and-title'>
                            <h3>{I18n.t('dashboard.roche.graphics.benefitsPerProductTitle')}</h3>
                            <TreeMapGraph
                                data={dashboardData?.bonificaciones_por_producto || []}
                                loading={loading}
                                isNex={false}
                            />
                        </div>
                        <div className='graph-and-title'>
                            <h3>{I18n.t('dashboard.roche.graphics.benefitsMonthlyTitle')}</h3>
                            <BarsGraph
                                data={dashboardData?.bonificaciones_por_mes || []}
                                loading={loading}
                                isNex={false}
                            />
                        </div>
                    </div>
                )}
            </div>
            {selectedOS && (
                <div className='benefits-container'>
                    <div className='card-head card-head-props card-head-mod'>
                        <h3>{I18n.t('dashboard.roche.benefits.title')}</h3>
                    </div>
                    <BenefitsTable data={dashboardData?.contratos_data || []} />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    migrationStatus: state.patient.patientMigrationStatus,
    userType: state.profile.user.type,
    osMigrated: state.session.migrated,
    osId: state.session.os_id,
});

const mapDispatchToProps = (dispatch: any) => ({
    migratePatients: () => dispatch(actions.patient.patientMigration()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RocheDashboard);
