import React, { useEffect, useState } from 'react';
import './OSDashboard.scss';
import { connect } from 'react-redux';
import DateRangePicker from '../../common/components/DateRangePicker';
import { useOSDashboardQuery } from '../../queries';
import Loader from '../../common/components/Loader';
import { format, parse } from 'date-fns';
import actions from '../../actions';
import { I18n } from 'react-redux-i18n';
import MigrationModal from '../components/MigrationModal/MigrationModal';
import { BenefitsTable } from '../benefits-table/BenefitsTable';
import { TreeMapGraph } from '../graphs/TreeMapGraph';
import { BarsGraph } from '../graphs/BarsGraph';

const numberToCurrency = (number: number) => {
    const newn = new Intl.NumberFormat('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
    return newn;
};

interface DashboardProps {
    migrationStatus: any;
    userType: any;
    osMigrated: any;
    osId: any;
    migratePatients: () => void;
    logout: () => void;
}

const OSDashboard = (props: DashboardProps) => {
    const [defaultStartDate, setDefaultStartDate] = useState<string | null>(null);
    const [defaultEndDate, setDefaultEndDate] = useState<string | null>(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { data: dashboardData, isLoading } = useOSDashboardQuery({
        startDate,
        endDate,
        defaultStartDate,
        defaultEndDate,
    });

    const showBeneftsData =
        dashboardData &&
        dashboardData.bonificaciones_por_producto?.length > 0 &&
        dashboardData.bonificaciones_por_mes?.length > 0;

    useEffect(() => {
        if (dashboardData?.desde) setDefaultStartDate(dashboardData.desde);
        if (dashboardData?.hasta) setDefaultEndDate(dashboardData.hasta);
    }, [dashboardData]);

    const getCardsData = () => {
        let result = [
            {
                title: I18n.t('dashboard.os.cards.totalPatients'),
                value: numberToCurrency(dashboardData?.pacientes_totales || 0),
            },
            {
                title: I18n.t('dashboard.os.cards.contracts'),
                value: numberToCurrency(dashboardData?.contratos || 0),
            },
            {
                title: I18n.t('dashboard.roche.cards.nexTotalReceived'),
                value: numberToCurrency(dashboardData?.tokens_totales_recibidos || 0),
            },
        ];
        if (showBeneftsData) {
            result.push({
                title: I18n.t('dashboard.os.cards.benefitsTotal'),
                value: numberToCurrency(dashboardData?.bonificaciones_totales || 0),
            });
        }
        return result;
    };

    return (
        <div className='os-dashboard'>
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
            />
            <div className='cards-container'>
                {getCardsData().map((cardData, i) => (
                    <div key={`dashboard-card-${i}`} className='dashboard-card'>
                        <h3 className='title'>{cardData.title}</h3>
                        {isLoading ? <Loader /> : <h1 className='value'>{cardData.value}</h1>}
                    </div>
                ))}
            </div>
            <div className='graphs-container'>
                <div className='filters'>
                    <DateRangePicker
                        allTransactions={[]}
                        textToSearch=''
                        startDate={setStartDate}
                        endDate={setEndDate}
                        updateFilteredTransactionsByDateRange={() => null}
                        clearDateRangeSelection={() => {
                            if (!dashboardData) return;
                            setStartDate(
                                format(
                                    parse(dashboardData?.desde, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                            setEndDate(
                                format(
                                    parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                        }}
                        searchText={() => null}
                        setPage={() => null}
                        showMonthYearPicker
                        receiveDates={
                            dashboardData
                                ? {
                                      startDate: parse(dashboardData.desde, 'MMyyyy', new Date()),
                                      endDate: parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                  }
                                : undefined
                        }
                        notShowReset
                        startDateLabel={I18n.t('dashboard.os.filters.from')}
                        endDateLabel={I18n.t('dashboard.os.filters.to')}
                    />
                </div>
                <div className='bottom-row'>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.os.graphics.savingsPerProductTitle')}</h3>
                        <TreeMapGraph
                            data={dashboardData?.ahorro_por_producto || []}
                            loading={isLoading}
                            isNex
                        />
                    </div>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.os.graphics.savingsMonthlyTitle')}</h3>
                        <BarsGraph
                            data={dashboardData?.ahorro_por_mes || []}
                            loading={isLoading}
                            isNex
                        />
                    </div>
                </div>
                {showBeneftsData && (
                    <div className='bottom-row'>
                        <div className='graph-and-title'>
                            <h3>{I18n.t('dashboard.os.graphics.benefitsPerProductTitle')}</h3>
                            <TreeMapGraph
                                data={dashboardData?.bonificaciones_por_producto || []}
                                loading={isLoading}
                                isNex={false}
                            />
                        </div>
                        <div className='graph-and-title'>
                            <h3>{I18n.t('dashboard.os.graphics.benefitsMonthlyTitle')}</h3>
                            <BarsGraph
                                data={dashboardData?.bonificaciones_por_mes || []}
                                loading={isLoading}
                                isNex={false}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className='benefits-container'>
                <div className='card-head card-head-props card-head-mod'>
                    <h3>{I18n.t('dashboard.roche.benefits.title')}</h3>
                </div>
                <BenefitsTable data={dashboardData?.contratos_data || []} />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    migrationStatus: state.patient.patientMigrationStatus,
    userType: state.profile.user.type,
    osMigrated: state.session.migrated,
    osId: state.session.os_id,
});

const mapDispatchToProps = (dispatch: any) => ({
    migratePatients: () => dispatch(actions.patient.patientMigration()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OSDashboard);
